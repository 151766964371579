import 'antd/dist/antd.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import './assets/scss/import.scss'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import trTR from 'antd/lib/locale/tr_TR'
import { ConfigProvider } from 'antd'

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={trTR}>
        <App />
      </ConfigProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorker.unregister()
