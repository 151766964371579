import {
  AimOutlined,
  HistoryOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
  UserOutlined,
  FlagOutlined,
  MailOutlined,
  
} from '@ant-design/icons'
import { Menu } from 'antd'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { RoutePath } from '../../enums/Routes'
import { UserTypeEnum } from '../../enums/userTypeEnum'
import { currentUserState } from '../../store/user'
import { isNonAuthPage } from '../../utils/urls'

const Navigation = (): JSX.Element => {
  const history = useHistory()
  const local = useLocation()
  let item

  const currentUser = useRecoilValue(currentUserState)

  const navItems = () => [
    {
      index: 0,
      icon: <UserAddOutlined />,
      label: 'Kullanıcılar',
      path: RoutePath.USER,
      isVisible: currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 1,
      icon: <OrderedListOutlined />,
      label: 'Açık Taleplerim',
      path: RoutePath.ORDER,
      isVisible: currentUser?.userType == UserTypeEnum.Customer || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 2,
      icon: <HistoryOutlined />,
      label: 'Tüm Taleplerim',
      path: RoutePath.ALL_ORDERS,
      isVisible: currentUser?.userType == UserTypeEnum.Customer || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 4,
      icon: <HistoryOutlined />,
      label: 'Açık Talepler',
      path: RoutePath.AVAILABLE_ORDERS,
      isVisible: currentUser?.userType == UserTypeEnum.Company || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 5,
      icon: <HistoryOutlined />,
      label: 'Tekliflerim',
      path: RoutePath.OFFERED_ORDERS,
      isVisible: currentUser?.userType == UserTypeEnum.Company || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 6,
      icon: <QuestionCircleOutlined />,
      label: 'Firmalar',
      path: RoutePath.COMPANY,
      isVisible: currentUser?.userType == UserTypeEnum.Customer || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 7,
      icon: <AimOutlined />,
      label: 'Kategoriler',
      path: RoutePath.CATEGORY,
      isVisible: currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 8,
      icon: <UserOutlined />,
      label: 'Profilim',
      path: generatePath(RoutePath.PROFILE_DETAIL, { id: currentUser?.id ?? 0 }),
      isVisible: true,
    },
    {
      index: 9,
      icon: <NotificationOutlined />,
      label: 'Ödeme Bildirimi',
      path: generatePath(RoutePath.APPLICATIONS),
      isVisible: currentUser?.userType == UserTypeEnum.Company || currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 10,
      icon: <FlagOutlined/>,
      label: 'Banner',
      path: generatePath(RoutePath.BANNERS),
      isVisible:  currentUser?.userType == UserTypeEnum.Admin,
    },
    {
      index: 11,
      icon: <MailOutlined/>,
      label: 'Destek',
       path: generatePath(RoutePath.SUPPORT_DETAIL),
      isVisible:  currentUser?.userType == UserTypeEnum.Admin || currentUser?.userType == UserTypeEnum.Company || currentUser?.userType == UserTypeEnum.Customer,
    },
  ]

  if (!isNonAuthPage(local.pathname)) {
    item = navItems().find(
      (item: any) => local.pathname.indexOf(item.path) !== -1
    )
  }

  return (
    <Menu defaultSelectedKeys={[`${item?.index}`]} mode="inline">
      {navItems().map((item: any, index: number) => {
        return item.children !== undefined ? (
          <Menu.SubMenu key={index} icon={item.icon} title={item.label}>
            {item.children.map((subItem: any) => {
              {
              }
              return (
                <Menu.Item
                  key={subItem.key}
                  icon={subItem.icon}
                  onClick={() => history.push(subItem.path)}
                >
                  {subItem.label}
                </Menu.Item>
              )
            })}
          </Menu.SubMenu>
        ) : (
          item.isVisible && (
            <Menu.Item
              key={index}
              icon={item.icon}
              onClick={() => history.push(item.path)}
            >
              {item.label}
            </Menu.Item>
          )
        )
      })}
    </Menu>
  )
}

export default Navigation
