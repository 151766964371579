import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { RoutePath } from '../enums/Routes'
import { isNonAuthPage } from './urls'

export type ErrorResultType = { type: string; error: AxiosError }

const HTTP_STATUS = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  405: 'METHOD_NOT_ALLOWED',
  500: 'INTERNAL_ERROR',
}

type errorType<T> = {
  commandResponseType: number
  errorCode: null | number
  isSucceed: boolean
  message: string
  data: T
}
export const handleFieldErrors = (error: errorType<any>): void => {
  if (error.errorCode == 100) {
    error.data.map((item: any) => {
      item?.value?.map((detailItem: any) => {
        message?.error(detailItem?.errorMessage)
      })
    })
  } else if (error.errorCode == 101) {
    error.data.map((item: any) => {
      message?.error(item?.errorMessage)
    })
  } else {
    message?.error(error.message)
  }
}

export const handleUnauthorized = (error: AxiosError): void => {
  const { data } = error.response as AxiosResponse
  message.error(data.message)
  window.location.href = RoutePath.AUTH_LOGIN
}

export const normalizeErrors = (
  error: AxiosError
): Promise<ErrorResultType> => {
  const status = error.response?.status || 500
  const errorResult = { type: HTTP_STATUS['500'], error: error }

  switch (status) {
    case 400: {
      errorResult.type = HTTP_STATUS['400']
      handleFieldErrors(error.response?.data)
      break
    }
    case 401: {
      errorResult.type = HTTP_STATUS['401']
      if (!isNonAuthPage(window.location.pathname)) {
        window.location.href = RoutePath.AUTH_LOGOUT
      }
      break
    }
    case 403: {
      errorResult.type = HTTP_STATUS['403']
      message?.error(error.response?.data?.message)
      break
    }
    case 404: {
      errorResult.type = HTTP_STATUS['404']
      message.error(error.response?.data?.message)
      break
    }
    case 500: {
      errorResult.type = HTTP_STATUS['500']
      if (error.message == 'Network Error') {
        message?.error('Sunucuya bağlanılamıyor.')
      } else {
        message?.error(error.response?.data?.message)
      }
      break
    }
  }
  return Promise.reject(errorResult)
}
