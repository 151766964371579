export enum RoutePath {
  HOME = '/',

  LANDING = '/landing',

  AUTH = '/auth',
  AUTH_LOGIN = '/auth/login',
  AUTH_REGISTER = '/auth/register',
  AUTH_COMPANY_LOGIN = '/auth/companyLogin',
  AUTH_FORGOT_PASSWORD = '/auth/forgotpassword',
  AUTH_COMPANY_REGISTER = '/auth/companyRegister',
  AUTH_LOGOUT = '/auth/logout',

  USER = '/user',
  USER_DETAIL = '/user/:id',
  USER_CREATE = '/user/create/:id',
  USER_EDIT = '/user/edit/:id',

  PREGNANCY = '/pregnancy',
  PREGNANCY_DETAIL = '/pregnancy/:id',
  PREGNANCY_CREATE = '/pregnancy/create/:id',
  PREGNANCY_EDIT = '/pregnancy/edit/:id',

  PREGNANCY_CATEGORY = '/pregnancy-category',
  PREGNANCY_CATEGORY_DETAIL = '/pregnancy-category/:id',
  PREGNANCY_CATEGORY_CREATE = '/pregnancy-category/create/:id',
  PREGNANCY_CATEGORY_EDIT = '/pregnancy-category/edit/:id',

  PREGNANCY_ARTICLE = '/pregnancy-article',
  PREGNANCY_ARTICLE_DETAIL = '/pregnancy-article/:id',
  PREGNANCY_ARTICLE_CREATE = '/pregnancy-article/create/:id',
  PREGNANCY_ARTICLE_EDIT = '/pregnancy-article/edit/:id',

  STORY = '/story',
  STORY_DETAIL = '/story/:id',
  STORY_CREATE = '/story/create/:id',
  STORY_EDIT = '/story/edit/:id',

  BANNERS = '/banners',
  BANNERS_CREATED = '/banners/create',
  BANNERS_EDIT = '/banners/edit/:id',
  BANNERS_DETAIL = '/banners/:id',

  APPLICATIONS = '/applications',
  APPLICATIONS_CREATED = '/applications/create',
  APPLICATIONS_EDIT = '/applications/edit/:id',
  APPLICATIONS_DETAIL = '/applications/:id',

  CATEGORY = '/category',
  CATEGORY_CREATED = '/category/create',
  CATEGORY_EDIT = '/category/edit/:id',
  CATEGORY_DETAIL = '/category/:id',


  PROFILE_DETAIL = '/profile/:id',
  PROFILE_EDIT = '/profile/edit/:id',

  ORDER = '/order',
  ALL_ORDERS = '/order/all',
  AVAILABLE_ORDERS = '/order/availableOrders',
  OFFERED_ORDERS = '/order/offeredOrders',
  ORDER_DETAIL = '/order/:id',
  ORDER_DETAIL_FOR_COMPANY = '/order/company/:id',
  ORDER_CREATE = '/order/create/:id',
  ORDER_EDIT = '/order/edit/:id',

  TICKET = '/ticket',
  TICKET_DETAIL = '/ticket/:id',
  TICKET_CREATE = '/ticket/create/:id',
  TICKET_EDIT = '/ticket/edit/:id',

  MANUAL = '/manual',
  MANUAL_DETAIL = '/manual/:id',
  MANUAL_CREATE = '/manual/create/:id',
  MANUAL_EDIT = '/manual/edit/:id',

  FAQ = '/faq',
  FAQ_DETAIL = '/faq/id',
  FAQ_CREATE = '/faq/create/:id',
  FAQ_EDIT = '/faq/edit/:id',

  SMART_WATCH = '/smartwatch',
  SMART_WATCH_DETAIL = '/smartwatch/:id',
  SMART_WATCH_CREATE = '/smartwatch/create/:id',
  SMART_WATCH_EDIT = '/smartwatch/edit/:id',

  SUPPORT = '/support',
  SUPPORT_DETAIL='/support/id',

  COMPANY = '/company',

  COMPANY_PACKAGES = '/packages',

}
