import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import AuthGuard from './components/AuthGuard/AuthGuard'
import Loading from './components/Loading/Loading'
import MasterPage from './components/MasterPage/MasterPage'
import { RoutePath } from './enums/Routes'



const Pages = {
  AUTH: lazy(() => import('./pages/Auth')),
  USER: lazy(() => import('./pages/User')),
  BANNERS: lazy(() => import('./pages/Banners')),
  NOMATCH: lazy(() => import('./pages/NoMatch')),
  ORDER: lazy(() => import('./pages/Order')),
  TICKET: lazy(() => import('./pages/Ticket')),
  MANUAL: lazy(() => import('./pages/Manual')),
  FAQ: lazy(() => import('./pages/Faq')),
  SMART_WATCH: lazy(() => import('./pages/SmartWatch')),
  CATEGORY: lazy(() => import('./pages/Category')),
  COMPANY: lazy(() => import('./pages/Company')),
  PACKAGES: lazy(() => import('./pages/Packages')),
  LANDING: lazy(() => import('./pages/Landing')),
  PROFILE: lazy(() => import('./pages/Profile')),
  APPLICATION: lazy(() => import('./pages/Application')),
  SUPPORT: lazy(() => import('./pages/Support')),
}

const App = (): JSX.Element => (
  <RecoilRoot>
    <Router>
      <Suspense fallback={<Loading />}>
        <AuthGuard>
          <MasterPage>
            <Switch>
              <Route path={RoutePath.AUTH} component={Pages.AUTH} />
              <Route exact path={RoutePath.HOME}>
                <Redirect to={RoutePath.LANDING} />
              </Route>
              <Route path={RoutePath.LANDING} component={Pages.LANDING} />
              <Route path={RoutePath.USER} component={Pages.USER} />
              <Route path={RoutePath.ORDER} component={Pages.ORDER} />
              <Route path={RoutePath.TICKET} component={Pages.TICKET} />
              <Route path={RoutePath.BANNERS} component={Pages.BANNERS} />
              <Route path={RoutePath.MANUAL} component={Pages.MANUAL} />
              <Route path={RoutePath.FAQ} component={Pages.FAQ} />
              <Route path={RoutePath.SMART_WATCH} component={Pages.SMART_WATCH} />
              <Route path={RoutePath.CATEGORY} component={Pages.CATEGORY} />
              <Route path={RoutePath.COMPANY} component={Pages.COMPANY} />
              <Route path={RoutePath.COMPANY_PACKAGES} component={Pages.PACKAGES} />
              <Route path={RoutePath.PROFILE_DETAIL} component={Pages.PROFILE} />
              <Route path={RoutePath.APPLICATIONS} component={Pages.APPLICATION} />
              <Route path={RoutePath.BANNERS} component={Pages.BANNERS}/>
              <Route path={RoutePath.SUPPORT} component={Pages.SUPPORT}/>
              <Route path="*" component={Pages.NOMATCH} />

            </Switch>
          </MasterPage>
        </AuthGuard>
      </Suspense>
    </Router>
  </RecoilRoot>
)

export default App
