import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { RoutePath } from '../../enums/Routes'
import { currentUserState } from '../../store/user'
import styles from './AvatarMenu.module.scss'

const AvatarMenu = (): JSX.Element => {
  const history = useHistory()
  const currentUser = useRecoilValue(currentUserState)
  const { t } = useTranslation()
  const menu = (
    <Menu>
      <Menu.Item
        danger
        icon={<LogoutOutlined />}
        onClick={() => history.push(RoutePath.AUTH_LOGOUT)}
      >
        {t('text.logout')}
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} placement="bottomLeft" className={styles.avatarmenu}>
      <div className="d-flex align-items-center">
        <Avatar icon={<UserOutlined />} className={styles.avatar} />
        <span className={styles.userName}>
          {currentUser?.nameSurname} <DownOutlined />
        </span>
      </div>
    </Dropdown>
  )
}

export default AvatarMenu
