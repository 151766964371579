import { Col, Image, Layout, Row } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import { ReactNode, useEffect, useState } from 'react'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import logo from '../../assets/img/logo-1.png'
import { RoutePath } from '../../enums/Routes'
import { currentUserState } from '../../store/user'
import { isCompanyAuth, isNonAuthPage } from '../../utils/urls'
import AvatarMenu from '../AvatarMenu/AvatarMenu'
import Navigation from '../Navigation/Navigation'
import styles from './MasterPage.module.scss'
import { BannerListEntity } from '../../types/entities/banner'
import { AxiosResponse } from 'axios'
import API from '../../utils/api'

type MasterPageProps = {
  children: ReactNode
}

const MasterPage = ({ children }: MasterPageProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const isAuthenticated = isNonAuthPage(location.pathname)
  const isCompanyAuthentication = isCompanyAuth(history.location.pathname)
  const [banners, setBanners] = useState<BannerListEntity[]>()

  const currentUser = useRecoilValue(currentUserState)

  const getBanners = () => {
    setBanners(undefined)

    API.get('/Home/GetBanners').then(({ data }: AxiosResponse<any>) => {
      setBanners(data?.data)
    })
  }

  useEffect(() => {
    getBanners()
  }, [])

  return (
    <main className={styles.wrapper}>
      <Header className={styles.header}>
        <Row className="w-100">
          <Col span={6} className={styles.logoWrap}>
            <Link className={styles.logo} to={currentUser?.userType === 1 ? RoutePath.ORDER : (currentUser?.userType === 2 ? RoutePath.AVAILABLE_ORDERS : (currentUser?.userType === 3 ? RoutePath.USER : RoutePath.LANDING))} >
              <Image
                preview={false} src={logo} />
            </Link>
          </Col>
          {!isAuthenticated && (
            <>
              <Col span={12} style={{ textAlign: 'center' }}>
                {currentUser?.userType === 2 && currentUser?.isUserSubcribed == false &&
                  <Link
                    style={{ color: 'red', fontSize: 22 }}
                    to={generatePath(RoutePath.COMPANY_PACKAGES)}>
                    {'Aktif üyeliğiniz bulunmamaktadır. Paketleri görmek için tıklayınız.'}
                  </Link>
                }
              </Col>
              <Col span={6} className={styles.right}>
                <AvatarMenu />
              </Col>
            </>
          )}
        </Row>
      </Header>
      <Layout className={styles.body}>
        {!isAuthenticated && (
          <Sider
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            className={styles.sider}>
            <Navigation />
          </Sider>
        )}
        <Layout className={isCompanyAuthentication ? styles.containerCompany : styles.container}>
          {!isAuthenticated && banners && <Row gutter={24} className="w-100" style={{ padding: 15 }}>
            {banners[0] &&
              <Col xs={24} xl={12} lg={12} md={12} className={styles.bannerClass}>
                <Link className={styles.logo} to={banners[0]?.link} >
                  <Image style={{ maxHeight: 120 }}
                    preview={false} src={banners[0]?.medias[0]?.path} />
                </Link>
              </Col>
            }
            {banners[1] &&
              <Col xs={24} xl={12} lg={12} md={12} className={styles.bannerClass}>
                <Link className={styles.logo} to={banners[1]?.link} >
                  <Image style={{ maxHeight: 120 }}
                    preview={false} src={banners[1]?.medias[0]?.path} />
                </Link>
              </Col>
            }
          </Row>
          }
          <Content className={styles.content}>{children}</Content>
        </Layout>
      </Layout>
    </main>
  )
}

export default MasterPage
