import { AxiosResponse } from 'axios'
import { ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { RoutePath } from '../../enums/Routes'
import { currentUserState } from '../../store/user'
import { ResponseEntity } from '../../types/common/response'
import { UserEntity } from '../../types/entities/user'
import API from '../../utils/api'
import { isNonAuthPage } from '../../utils/urls'

type AuthGuardProps = {
  children: ReactNode
}

const AuthGuard = ({ children }: AuthGuardProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const token = localStorage.getItem('token')
  const setCurrentUser = useSetRecoilState(currentUserState)

  useEffect(() => {
    if (token && !isNonAuthPage(history.location.pathname)) {
      API.get('/Account/Me')
        .then(({ data }: AxiosResponse<ResponseEntity<UserEntity>>) => {
          setCurrentUser(data?.data)
        })
        .catch(() => {
          setCurrentUser(null)
          if (!isNonAuthPage(history.location.pathname)) {
            history.push(RoutePath.AUTH_LOGIN)
          }
        })
    }
  }, [token])

  useEffect(() => {
    if (!token && !isNonAuthPage(history.location.pathname)) {
      history.push(RoutePath.AUTH_LOGIN)
    }
  }, [location.pathname])

  return <>{children}</>
}
export default AuthGuard
