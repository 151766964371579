import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { normalizeErrors } from './error'

export const API_URL =
  process.env.REACT_APP_API_URL || process.env.REACT_APP_API_FALLBACK_URL

export const CDN_URL = process.env.REACT_APP_CDN_FALLBACK_URL

axios.defaults.withCredentials = true
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false })
}

const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

const requestBefore = (config: any): AxiosRequestConfig => {
  const { params = {} } = config
  config.headers.get['X-Bone-PageIndex'] = params.page || 1
  config.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
    'token'
  )}`

  return config
}

const requestError = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error)
const responseError = normalizeErrors

const responseBefore = (response: AxiosResponse): AxiosResponse => {
  return response
}

API.interceptors.request.use(requestBefore, requestError)
API.interceptors.response.use(responseBefore, responseError)

export default API
